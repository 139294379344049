import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover, Transition, PopoverButton, PopoverPanel, } from '@headlessui/react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { Checkbox } from '@components/Base/Checkbox';
import TextDescription from '@components/Base/TextDescription';
import User from '@components/Base/UserDetails';
import { convertToTitleCase } from '@components/ProctorNgSessions/SessionTable/helper';
import { classNames } from '@utils/classNames';
import { formatDate, ValidVoucher } from '@utils/dateFormat';
import gripHorizontalIcon from 'app/images/grip-horizontal.svg';
export const createColumns = () => [
    {
        id: 'voucher code',
        header: () => 'Voucher Code',
        accessorKey: 'code',
        enableSorting: false,
        hidden: false,
        cell: ({ row }) => _jsx("div", { children: row.original.code || '-' }),
        size: 200,
        className: 'text-left text-sm font-medium font-roboto text-gray-900 left-[0px] sticky attendee-column shadow-[inset_-1px_0px_0px_0px] shadow-gray-400/20',
        cellClassName: 'text-left sticky left-[0px] attendee-column shadow-[inset_-1px_0px_0px_0px] z-[1] shadow-gray-400/20',
    },
    {
        id: 'Discount',
        header: () => 'Discount',
        accessorKey: 'discount_percentage',
        enableSorting: false,
        cell: ({ row }) => row.original.discount_percentage
            ? `${row.original.discount_percentage}%`
            : '-',
        size: 100,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
        hidden: false,
    },
    {
        id: 'description',
        header: 'Description',
        accessorKey: 'description',
        enableSorting: false,
        cell: ({ row }) => {
            return _jsx("div", { children: row.original.description || '-' });
        },
        size: 250,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
    },
    {
        id: 'SKU',
        header: () => 'SKU',
        accessorKey: 'sku',
        enableSorting: false,
        size: 250,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left fit-content',
        hidden: false,
        cell: ({ row }) => {
            var _a;
            const skuCoupons = (_a = row === null || row === void 0 ? void 0 : row.original) === null || _a === void 0 ? void 0 : _a.sku_coupons;
            let combinedNames;
            if (skuCoupons === null || skuCoupons === void 0 ? void 0 : skuCoupons.length) {
                combinedNames = skuCoupons
                    .map((coupon) => { var _a; return (_a = coupon === null || coupon === void 0 ? void 0 : coupon.sku) === null || _a === void 0 ? void 0 : _a.name; })
                    .join(', ');
            }
            else {
                combinedNames = '-';
            }
            return (_jsx(TextDescription, { text: combinedNames !== null && combinedNames !== void 0 ? combinedNames : '-', showToolTip: true, maxNumberOfLines: 2, textClass: "w-[200px]" }));
        },
    },
    {
        id: 'Tags',
        header: () => 'Tags',
        accessorKey: 'tags',
        enableSorting: false,
        cell: ({ row }) => {
            var _a;
            const tagCoupons = (_a = row === null || row === void 0 ? void 0 : row.original) === null || _a === void 0 ? void 0 : _a.tag_coupons;
            let combinedNames;
            if (tagCoupons === null || tagCoupons === void 0 ? void 0 : tagCoupons.length) {
                combinedNames = tagCoupons
                    .map((coupon) => { var _a; return (_a = coupon === null || coupon === void 0 ? void 0 : coupon.tag) === null || _a === void 0 ? void 0 : _a.value; })
                    .join(', ');
            }
            else {
                combinedNames = '-';
            }
            if (combinedNames != '-') {
                return (_jsx(TextDescription, { text: combinedNames !== null && combinedNames !== void 0 ? combinedNames : '-', showToolTip: true, maxNumberOfLines: 2, textClass: "w-[200px]" }));
            }
            else {
                return _jsx("span", { children: "-" });
            }
        },
        size: 80,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
        hidden: false,
    },
    {
        id: 'Max Use',
        header: () => 'Max Use',
        accessorKey: 'max_count',
        enableSorting: false,
        cell: ({ row }) => {
            return _jsx("div", { children: row.original.max_count || '-' });
        },
        size: 80,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
        hidden: false,
    },
    {
        id: 'Expires On',
        header: () => 'Expires On',
        accessorKey: 'valid_till',
        enableSorting: false,
        cell: ({ row }) => row.original.valid_till
            ? ValidVoucher(new Date(row.original.valid_till))
            : '-',
        size: 203,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left text-sm/[19px] font-normal',
        hidden: false,
    },
    {
        id: 'Created by',
        header: 'Created by',
        accessorKey: 'created_by_details',
        enableSorting: false,
        cell: ({ row }) => {
            var _a, _b;
            const email = ((_a = row.original.created_by_details) === null || _a === void 0 ? void 0 : _a.email)
                ? row.original.created_by_details.email
                : ((_b = row.original.created_by_details) === null || _b === void 0 ? void 0 : _b.name)
                    ? row.original.created_by_details.name
                    : '';
            return (_jsx(User, { data: {
                    name: email,
                }, size: "sm", hideImage: false, additionalContent: row.original.created_at
                    ? formatDate(new Date(row.original.created_at), 'withTime')
                    : '' }));
        },
        size: 250,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
    },
    {
        id: 'menu',
        enableSorting: false,
        accessorKey: 'menu',
        size: 48,
        className: 'text-left right-0 sticky shadow-[inset_0px_0px_0px_-1px] shadow-gray-400/20',
        cellClassName: 'text-center right-0 sticky z-[1] shadow-[inset_0px_0px_0px_-1px] shadow-gray-400/20',
        header: ({ table }) => {
            return (_jsx("div", { children: _jsxs(Popover, { className: "flex justify-end items-center pr-3", children: [_jsx(PopoverButton, { className: classNames('focus:outline-none'), children: _jsx(PlusCircleIcon, { className: classNames('text-black h-5 w-5 focus:outline-none'), "aria-hidden": "true", "data-testid": "hide-column" }) }), _jsx(Transition, { children: _jsx(PopoverPanel, { children: _jsx("div", { className: "bg-white border-solid border-[1px] w-[214px] font-normal shadow-sm max-h-[480px] overflow-y-auto rounded-md px-4 absolute right-0 top-12", "data-testid": "column-model", children: table.getAllLeafColumns().map((column) => {
                                        if (column.id === 'menu') {
                                            return null;
                                        }
                                        return (_jsxs("div", { className: "h-[40px] w-full flex items-center", children: [_jsx("span", { className: "pr-2 w-6 h-6", children: _jsx("img", { src: gripHorizontalIcon, alt: "edit", className: "w-full h-full" }) }), _jsx(Checkbox, { label: typeof column.columnDef.header === 'string'
                                                        ? column.columnDef.header
                                                        : convertToTitleCase(column.id), id: column.id, size: "md", checked: column.getIsVisible(), onChange: column.getToggleVisibilityHandler(), disabled: false })] }, column.id));
                                    }) }) }) })] }) }));
        },
    },
];
