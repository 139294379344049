import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select, { components, } from 'react-select';
import { Button } from '@components/Base/Button';
import Modal from '@components/Base/Modal';
import User from '@components/Base/UserDetails';
import DatePicker from '@components/Shared/DatePicker';
const AssignProctor = ({ openAddFlowModal, handleCloseModal, data, onRequestReview, selectedCount, hasInProgressSession, headerText, isEdit, }) => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const options = data === null || data === void 0 ? void 0 : data.map((user) => ({
        value: user.id || 0,
        label: `${user.name} (${user.email})`,
    }));
    const handleChange = (selectedOption) => {
        const selectedId = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value;
        const foundUser = data.find(({ id }) => id === selectedId);
        setSelectedUser(foundUser || null);
    };
    const onDateSelect = (date) => {
        setSelectedDate(date);
    };
    const CustomOption = (props) => {
        const user = data.find(({ id }) => id === props.data.value);
        return (_jsx(components.Option, Object.assign({}, props, { children: user ? (_jsx(User, { data: user, additionalContent: user.email })) : (_jsx("span", { children: "No User Found" })) })));
    };
    const customStyles = {
        option: (provided, state) => (Object.assign(Object.assign({}, provided), { backgroundColor: state.isSelected
                ? '#e0e0e0'
                : state.isFocused
                    ? '#f0f0f0'
                    : '#fff', color: state.isSelected ? '#000' : '#333' })),
        singleValue: (provided) => (Object.assign(Object.assign({}, provided), { color: '#333' })),
        dropdownIndicator: (provided) => (Object.assign(Object.assign({}, provided), { padding: '0' })),
        indicatorSeparator: () => ({
            display: 'none',
        }),
    };
    const requestReview = () => {
        onRequestReview({ user: selectedUser, date: selectedDate });
        handleCloseModal();
    };
    return (_jsxs(Modal, { size: "sm", show: openAddFlowModal, onCancel: handleCloseModal, closeOnOverlayClick: false, children: [headerText && !selectedCount && (_jsx("p", { className: "font-semibold", children: headerText })), selectedCount && (_jsx("p", { className: "font-semibold", children: `${selectedCount} sessions selected` })), _jsx("p", { className: "pb-1.5 pt-3 text-sm font-normal", children: "Assign Proctor" }), _jsxs("div", { className: "w-full", children: [_jsx(Select, { options: options, onChange: handleChange, placeholder: "Select a proctor...", components: { Option: CustomOption }, styles: customStyles, isSearchable: false }), _jsx("p", { className: "pt-4 pb-1.5 text-sm font-normal", children: "Due Date" }), _jsx(DatePicker, { onChange: onDateSelect })] }), hasInProgressSession && (_jsxs("div", { className: "h-14 bg-yellow-50 w-full mt-4 rounded flex items-center", children: [_jsx(FontAwesomeIcon, { icon: faTriangleExclamation, className: "text-amber-400 pl-4", size: "2xl", tabIndex: 0 }), _jsxs("span", { "data-testid": "in-progress-message", className: "font-normal text-sm pl-4", children: ["Sessions ", _jsx("span", { className: "font-medium", children: "In Progress" }), " will not be assigned."] })] })), _jsxs("div", { className: "flex justify-end pt-6", children: [_jsx(Button, { className: "ml-2 text-sm", onClick: handleCloseModal, variant: "outline", children: "Go Back" }), _jsx(Button, { className: "ml-2 text-sm", onClick: requestReview, variant: "base", children: isEdit ? 'Save Changes' : 'Request Review' })] })] }));
};
export default AssignProctor;
