import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { faCheck, faCopy } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';
import { FormattedMessage } from 'react-intl';
import { Button } from '@components/Base/Button';
import { downloadBulkCreatedVoucher } from '@containers/vouchers/helpers';
import CheckAnimation from '@images/CheckAnimation.json';
import messages from './messages';
const CreateVoucherSuccess = ({ createdVoucher, createVoucherDownloaded, }) => {
    const [copied, setCopied] = useState(false);
    const handleCopy = () => {
        if (createdVoucher) {
            navigator.clipboard.writeText(createdVoucher[0].code).then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            });
        }
    };
    const downloadVoucher = () => {
        if (createdVoucher) {
            downloadBulkCreatedVoucher(createdVoucher, 'coupons.xlsx');
            createVoucherDownloaded();
        }
    };
    return (_jsx("div", { className: "h-[580px]", children: _jsxs("div", { className: "text-center", children: [_jsx(LottiePlayer, { className: "relative max-w-[150px] w-full pt-24", autoplay: true, loop: true, src: CheckAnimation }), _jsx("p", { className: "text-lg font-medium", children: _jsx(FormattedMessage, Object.assign({}, messages.voucher_ready)) }), _jsx("p", { className: "mx-auto text-gray-500 text-sm mt-1.5", children: _jsx(FormattedMessage, Object.assign({}, messages.voucher_ready_description)) }), createdVoucher && createdVoucher.length > 1 ? (_jsxs(Button, { className: "mx-auto mt-3", onClick: downloadVoucher, children: [_jsx(ArrowDownTrayIcon, { className: "w-5 h-5 cursor-pointer mr-2" }), _jsx(FormattedMessage, Object.assign({}, messages.download_now))] })) : (_jsxs("button", { tabIndex: 0, className: "flex items-center justify-center mx-auto mt-3", onClick: handleCopy, "aria-label": "Copy to clipboard", children: [_jsx("span", { className: "mr-2 font-normal text-blue-700 cursor-pointer", "aria-hidden": "true", children: copied ? (_jsx(FormattedMessage, Object.assign({}, messages.copied))) : (_jsx(FormattedMessage, Object.assign({}, messages.copy_voucher))) }), copied ? (_jsx(FontAwesomeIcon, { icon: faCheck, className: "w-4 h-4 text-primary" })) : (_jsx(FontAwesomeIcon, { icon: faCopy, className: "w-4 h-4 cursor-pointer text-primary" }))] }))] }) }));
};
export default CreateVoucherSuccess;
