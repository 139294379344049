import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Button } from '@components/Base/Button';
import Dropdown from '@components/Base/Dropdown';
import Modal from '@components/Base/Modal';
import RadioButton from '@components/Base/Radio';
import { Textarea } from '@components/Base/Textarea';
import { Textbox } from '@components/Base/Textbox';
import CreateVoucherSuccess from '@components/CreateVoucherSuccess';
import DatePicker from '@components/Shared/DatePicker';
import { formateVoucherPayload, validateNumericInput, } from '@containers/vouchers/helpers';
import { VOUCHER_CREATION_TYPE, voucherCreationType, } from '@containers/vouchers/types';
import messages from './messages';
const VoucherModal = (props) => {
    const { handleCreateVoucher, openVoucherModal, toggleModal, skuList, loadingCreatedVoucher, createdVoucher, showVoucherSuccessModal, createVoucherDownloaded, } = props;
    const [selectedVoucherType, setSelectedVoucherType] = useState(VOUCHER_CREATION_TYPE.STANDALONE_CODE);
    const [voucherName, setVoucherName] = useState('');
    const [voucherQuantity, setVoucherQuantity] = useState('1');
    const [maxUse, setMaxUse] = useState('1');
    const [voucherDescription, setVoucherDescription] = useState('');
    const [voucherDiscount, setVoucherDiscount] = useState('');
    const [voucherTag, setVoucherTag] = useState('');
    const [expiryDate, setExpiryDate] = useState(null);
    const [discountError, setDiscountError] = useState('');
    const [selectedSku, setSelectedSku] = useState(null);
    const handleSelectedVoucherType = (value) => {
        setSelectedVoucherType(value);
    };
    const disabledCreateButton = () => {
        if (selectedVoucherType === VOUCHER_CREATION_TYPE.BULK_CODES) {
            return (voucherQuantity &&
                voucherDiscount &&
                maxUse &&
                !discountError &&
                selectedSku);
        }
        return (voucherName ||
            (voucherDiscount &&
                maxUse &&
                !discountError &&
                selectedSku &&
                Number(voucherQuantity) > 0 &&
                Number(maxUse) > 0));
    };
    const resetState = () => {
        setVoucherName('');
        setVoucherQuantity('1');
        setMaxUse('1');
        setVoucherDescription('');
        setVoucherDiscount('');
        setVoucherTag('');
        setExpiryDate(null);
        setDiscountError('');
        setSelectedSku(null);
    };
    const createVoucher = () => {
        const voucherData = formateVoucherPayload(maxUse, voucherDiscount, voucherDescription, selectedSku, expiryDate);
        handleCreateVoucher(voucherData, voucherQuantity);
    };
    const handleDiscountChange = (e) => {
        const value = e.target.value;
        if (validateNumericInput(value)) {
            setVoucherDiscount(value);
            if (parseInt(value) > 100) {
                setDiscountError(_jsx(FormattedMessage, Object.assign({}, messages.discount_validation_message)));
            }
            else {
                setDiscountError('');
            }
        }
    };
    const closeModal = () => {
        resetState();
        setSelectedVoucherType(VOUCHER_CREATION_TYPE.STANDALONE_CODE);
        toggleModal();
    };
    useEffect(() => {
        if (selectedVoucherType) {
            resetState();
        }
    }, [selectedVoucherType]);
    return (_jsx(Modal, { size: "md", show: openVoucherModal, onCancel: closeModal, children: showVoucherSuccessModal && createdVoucher && createdVoucher.length ? (_jsx(CreateVoucherSuccess, { createdVoucher: createdVoucher, createVoucherDownloaded: createVoucherDownloaded })) : (_jsxs(_Fragment, { children: [_jsx("p", { className: "text-xl font-semibold leading-6 text-gray-900", id: "modal-title", children: _jsx(FormattedMessage, Object.assign({}, messages.new_voucher)) }), _jsxs("div", { className: "flex text-sm align-middle text-gray-900 mt-8 bg-white", children: [_jsxs("div", { className: "mr-6", children: [_jsx(FormattedMessage, Object.assign({}, messages.type)), ":"] }), _jsx("div", { className: "flex", children: voucherCreationType.map((list) => (_jsx("div", { className: "pr-4 ", children: _jsx(RadioButton, { "data-testid": list.value, id: list.value, onChange: () => handleSelectedVoucherType(list.value), checked: !!selectedVoucherType.includes(list.value), name: VOUCHER_CREATION_TYPE.VOUCHER_CREATION, value: list.label, label: list.value, disabled: false }) }, list.value))) })] }), _jsx("div", { className: "flex align-middle pt-4 gap-3 bg-white", children: _jsxs("div", { className: "flex flex-1 gap-3", children: [_jsx("div", { className: "flex-auto w-1/3", children: _jsx(Textbox, { name: "voucheQuantity", "data-testid": "voucherQuantity", maxLength: 30, label: _jsx(FormattedMessage, Object.assign({}, messages.quantity)), required: true, error: Number(voucherQuantity) === 0 ? (_jsx(FormattedMessage, Object.assign({}, messages.min_quantity))) : (''), className: "inline-block flex-1", placeholder: 'Enter a quantity', value: voucherQuantity, onChange: (e) => {
                                        const value = e.target.value;
                                        if (validateNumericInput(value)) {
                                            setVoucherQuantity(value);
                                        }
                                    }, disabled: selectedVoucherType ===
                                        VOUCHER_CREATION_TYPE.STANDALONE_CODE }) }), _jsx("div", { className: "flex-auto w-2/3", children: _jsx(Textbox, { "data-testid": "voucherRedemptionLimit", name: "voucherRedemptionLimit", maxLength: 30, label: _jsx(FormattedMessage, Object.assign({}, messages.redemption_limit)), required: true, error: Number(maxUse) === 0 ? (_jsx(FormattedMessage, Object.assign({}, messages.min_max_use))) : (''), className: "inline-block flex-1", placeholder: 'Enter a redemption limit', value: maxUse, onChange: (e) => {
                                        const value = e.target.value;
                                        if (validateNumericInput(value)) {
                                            setMaxUse(value);
                                        }
                                    } }) })] }) }), _jsx("div", { className: "pt-4 bg-white flex-1", children: _jsx(Textarea, { maxLength: 100, value: voucherDescription, onChange: (e) => setVoucherDescription(e.target.value), placeholder: "Describe the voucher", label: _jsx(FormattedMessage, Object.assign({}, messages.description)), name: "voucherDescription", className: "resize-none" }) }), _jsxs("div", { className: "flex align-middle pt-4 gap-3 bg-white", children: [_jsxs("div", { className: "flex-1", children: [_jsxs("label", { htmlFor: "voucher-discount", className: "text-sm", children: [_jsx(FormattedMessage, Object.assign({}, messages.discount)), _jsx("span", { className: "pl-0.5", children: "*" })] }), _jsx("div", { className: "mt-1", children: _jsx("div", { className: "flex align-middle gap-3 bg-white", id: "voucher-discount", children: _jsx("div", { className: "flex-1", children: _jsx("div", { className: "flex flex-col relative w-full", tabIndex: -1, "data-testid": "date-input-box", children: _jsx("div", { className: classNames('border rounded flex items-center p-1.5 focus-within:border-blue-700 border-gray-200', discountError && 'focus-within:border-red-700'), children: _jsxs("div", { className: "flex items-center w-fit", children: [_jsx("input", { type: "text", placeholder: "00", value: voucherDiscount, onChange: (e) => {
                                                                    handleDiscountChange(e);
                                                                }, maxLength: 3, className: "text-center p-0 w-8 text-black placeholder-gray-500 border-none focus:outline-none focus:ring-0 focus:border-none" }), _jsx("span", { className: "text-base text-gray-900", children: "%" }), _jsx("input", { type: "text", onFocus: (e) => e.target.blur(), className: "text-center p-0 w-12 text-black placeholder-gray-500 border-none focus:outline-none focus:ring-0 focus:border-none" })] }) }) }) }) }) }), discountError && (_jsx("div", { "data-testid": "input_error", className: "text-xs text-red-700", children: discountError }))] }), _jsxs("div", { className: "flex-1", children: [_jsxs("label", { htmlFor: "voucher-skus", className: "text-sm", children: [_jsx(FormattedMessage, Object.assign({}, messages.sku)), _jsx("span", { className: "pl-0.5", children: "*" })] }), _jsx("div", { className: "mt-1", children: _jsx(Dropdown, { id: "voucher-skus", isMulti: false, value: selectedSku, isSearchable: true, placeholder: "Select", onChange: (sku) => {
                                            setSelectedSku(sku);
                                        }, options: skuList.length ? skuList : [] }) })] })] }), _jsxs("div", { className: "flex align-middle mt-4 gap-3 bg-white", children: [_jsx("div", { className: "flex-1", children: _jsx(Textbox, { name: "voucherTags", maxLength: 30, label: _jsx(FormattedMessage, Object.assign({}, messages.tags)), error: '', className: "inline-block flex-1", placeholder: 'Enter tags', value: voucherTag, onChange: (e) => {
                                    setVoucherTag(e.target.value);
                                } }) }), _jsxs("div", { className: "flex-1", children: [_jsx("label", { htmlFor: "voucher-datepicker", className: "text-sm", children: _jsx(FormattedMessage, Object.assign({}, messages.expiry)) }), _jsx("div", { id: "voucher-datepicker", className: "mt-1", children: _jsx(DatePicker, { onChange: (date) => {
                                            setExpiryDate(date);
                                        } }) })] })] }), _jsx("div", { className: "flex flex-row mt-8", children: _jsx(Button, { "data-testid": "create-voucher", onClick: createVoucher, isLoading: loadingCreatedVoucher, disabled: !disabledCreateButton(), children: _jsx(FormattedMessage, Object.assign({}, messages.create_Voucher)) }) })] })) }));
};
export default VoucherModal;
