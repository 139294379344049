var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import Header from '@components/Base/Header';
import { triggerToast } from '@components/Base/Notification';
import SomethingWentWrong from '@components/Error/SomethingWentWrong';
import VoucherList from '@components/Vouchers';
import { getMixpanelProperties } from '@utils/helpers';
import { useAppSelector, useAppDispatch } from '@utils/hooks';
import { proviewConsoleVoucherCreated, proviewConsoleVoucherDownloaded, proviewConsoleVoucherDownloadListClicked, proviewConsoleVoucherPageViewedEvent, } from '@utils/mixpanelActions';
import { createVoucher, formatInitialFilters, getFormattedFilters, getFormattedRedeemedFilters, getFormattedSearchConditions, } from './helpers';
import messages from './messages';
import { getPayVouchersAction, getSkuAction, getRedeemedVoucherAction, resetVoucherSuccessState, createVoucherAction, } from './slice';
import { SELECTED_TAB, VOUCHER_CREATION_TYPE } from './types';
const Vouchers = () => {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const parseInitialFilters = formatInitialFilters(searchParams);
    const filterDetails = parseInitialFilters();
    const { filters, search, tab } = filterDetails;
    const [searchTerm, setSearchTerm] = useState(search);
    const [selectedTab, setSelectedTab] = useState(tab);
    const [resetFilters, setResetFilters] = useState('');
    const [appliedFilters, updateAppliedFilters] = useState(filters);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [paginationRedeemed, setPaginationRedeemed] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const { data: vouchersData, isLoading, totalCount, error, skus: { skuList: skuData }, issuedVouchersList, redeemedVouchers, redeemedVoucherFullList, newlyCreatedVouchers, } = useAppSelector((state) => state.voucherList);
    const { user, isMixpanelInitialized } = useAppSelector((state) => state.app);
    const { data: redeemedVouchersList, isLoading: loadingRedeemedVouchers, totalCount: redeemedVouchersCount, } = redeemedVouchers;
    const { data: createdVoucher, isLoading: loadingCreatedVoucher, isSuccess: showVoucherSuccessModal, } = newlyCreatedVouchers;
    const { isLoading: loadingIssuedVouchersList } = issuedVouchersList;
    const { isLoading: loadingRedeemedVoucherFullList } = redeemedVoucherFullList;
    const updateURLParams = (tab) => {
        setSearchParams((params) => {
            params.set('tab', tab || selectedTab);
            const filters = Object.entries(appliedFilters).reduce((acc, [key, value]) => {
                if (value) {
                    if (Array.isArray(value)
                        ? value.length > 0
                        : typeof value === 'object'
                            ? Object.keys(value).length > 0
                            : true) {
                        acc[key] = value;
                    }
                }
                return acc;
            }, {});
            if (Object.keys(filters).length > 0) {
                params.set('filters', JSON.stringify(filters));
            }
            else {
                params.delete('filters');
            }
            if (searchTerm) {
                params.set('search', searchTerm.replace(/%/g, ''));
            }
            else {
                params.delete('search');
            }
            return params;
        }, { replace: true });
    };
    const handleSelectedTab = (value) => {
        setSelectedTab(value);
        updateURLParams(value);
        setResetFilters(value);
        setSearchTerm('');
        setPagination({ pageIndex: 0, pageSize: 10 });
        setPaginationRedeemed({ pageIndex: 0, pageSize: 10 });
    };
    const vouchers = useMemo(() => {
        return vouchersData.map((voucher) => ({
            label: voucher.code,
            value: voucher.code,
        }));
    }, [vouchersData]);
    const skusList = useMemo(() => {
        return skuData.map((session) => ({
            label: session.name,
            value: session.id,
        }));
    }, [skuData]);
    const redeemedList = useMemo(() => {
        const filteredOrders = redeemedVouchersList.filter((order) => order.ordered_by_details &&
            (order.ordered_by_details.username ||
                order.ordered_by_details.name ||
                order.ordered_by_details.id));
        const uniqueOrders = uniqBy(filteredOrders, (order) => { var _a; return (_a = order.ordered_by_details) === null || _a === void 0 ? void 0 : _a.id; });
        return uniqueOrders
            .map((order) => {
            const { username, name, id } = order.ordered_by_details || {};
            return {
                label: username || name || '',
                value: id || '',
            };
        })
            .filter((order) => order.label && order.value);
    }, [redeemedVouchersList]);
    const resetVoucherState = () => {
        dispatch(resetVoucherSuccessState());
    };
    const downloadCompleteList = () => {
        if (selectedTab === SELECTED_TAB.ISSUED) {
            dispatch(getPayVouchersAction({
                searchConditions: searchTerm
                    ? getFormattedSearchConditions(searchTerm, selectedTab)
                    : {},
                conditions: getFormattedFilters(appliedFilters),
                limit: null,
                offset: 0,
                fetchCompleteList: true,
            }));
        }
        else {
            dispatch(getRedeemedVoucherAction({
                searchConditions: searchTerm
                    ? getFormattedSearchConditions(searchTerm, selectedTab)
                    : {},
                conditions: getFormattedRedeemedFilters(appliedFilters),
                limit: null,
                offset: 0,
                fetchCompleteList: true,
            }));
        }
        voucherDownloadListClicked();
    };
    const createVoucherDownloaded = () => {
        createVoucherDownloadedEvent(isMixpanelInitialized, user, createdVoucher);
    };
    const voucherDownloadListClicked = () => {
        voucherDownloadListClickedEvent(isMixpanelInitialized, user, selectedTab);
    };
    const handleCreateVoucher = (createVoucherPayload, voucherQuantity) => __awaiter(void 0, void 0, void 0, function* () {
        const voucherCreationPayloads = yield createVoucher(voucherQuantity, createVoucherPayload);
        dispatch(createVoucherAction(voucherCreationPayloads));
    });
    useEffect(() => {
        if (createdVoucher === null || createdVoucher === void 0 ? void 0 : createdVoucher.length) {
            createVoucherMixpanelEvent(isMixpanelInitialized, user, createdVoucher);
        }
    }, [createdVoucher]);
    useEffect(() => {
        const baseSearchConditions = getFormattedSearchConditions(searchTerm, selectedTab);
        const filters = getFormattedFilters(appliedFilters);
        if (selectedTab === SELECTED_TAB.ISSUED) {
            updateURLParams();
            dispatch(getPayVouchersAction({
                searchConditions: searchTerm ? baseSearchConditions : {},
                conditions: filters,
                limit: pagination.pageSize,
                offset: pagination.pageIndex * pagination.pageSize,
            }));
        }
        else {
            const redeemedFilters = getFormattedRedeemedFilters(appliedFilters);
            updateURLParams();
            dispatch(getRedeemedVoucherAction({
                searchConditions: searchTerm ? baseSearchConditions : {},
                conditions: redeemedFilters,
                limit: paginationRedeemed.pageSize,
                offset: paginationRedeemed.pageIndex * paginationRedeemed.pageSize,
            }));
        }
    }, [
        searchTerm,
        appliedFilters,
        selectedTab,
        createdVoucher,
        pagination,
        paginationRedeemed,
    ]);
    useEffect(() => {
        if (!skuData.length) {
            dispatch(getSkuAction());
        }
    }, [skuData]);
    useEffect(() => {
        if (selectedTab === SELECTED_TAB.REDEEMED) {
            dispatch(getPayVouchersAction({
                searchConditions: {},
                conditions: {},
                limit: 10,
                offset: 0,
            }));
        }
    }, [selectedTab]);
    useEffect(() => {
        if (isMixpanelInitialized && user) {
            const mixpanelProperties = getMixpanelProperties(user);
            proviewConsoleVoucherPageViewedEvent(mixpanelProperties);
        }
    }, [isMixpanelInitialized, user.tenant]);
    if (error) {
        triggerToast({
            variant: 'danger',
            setting: { position: 'top-right' },
            message: {
                title: _jsx(FormattedMessage, Object.assign({}, messages.error_voucher_fetch_header)),
                summary: _jsx(FormattedMessage, Object.assign({}, messages.error_voucher_fetch_body)),
            },
        });
        return _jsx(SomethingWentWrong, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(Header, { details: { label: _jsx(FormattedMessage, Object.assign({}, messages.vouchers)) } }), _jsx(VoucherList, { appliedFilters: appliedFilters, updateAppliedFilters: updateAppliedFilters, voucherList: vouchersData, isLoading: isLoading, searchTerm: searchTerm, setSearchTerm: setSearchTerm, vouchers: vouchers, selectedTab: selectedTab, handleSelectedTab: handleSelectedTab, skuList: skusList, redeemedVouchersList: redeemedVouchersList, loadingRedeemedVouchers: loadingRedeemedVouchers, handleCreateVoucher: handleCreateVoucher, loadingCreatedVoucher: loadingCreatedVoucher, createdVoucher: createdVoucher, showVoucherSuccessModal: showVoucherSuccessModal, resetVoucherState: resetVoucherState, redeemedList: redeemedList, resetFilters: resetFilters, totalCount: totalCount, pagination: pagination, setPagination: setPagination, redeemedVouchersCount: redeemedVouchersCount, paginationRedeemed: paginationRedeemed, setPaginationRedeemed: setPaginationRedeemed, createVoucherDownloaded: createVoucherDownloaded, voucherDownloadListClicked: voucherDownloadListClicked, downloadCompleteList: downloadCompleteList, loadingIssuedVouchersList: loadingIssuedVouchersList, loadingRedeemedVoucherFullList: loadingRedeemedVoucherFullList })] }));
};
export default Vouchers;
export const showCreateVoucherErrorToast = () => {
    triggerToast({
        variant: 'danger',
        setting: { position: 'top-right' },
        message: {
            title: _jsx(FormattedMessage, Object.assign({}, messages.voucher_creation_failed)),
            summary: (_jsx(FormattedMessage, Object.assign({}, messages.voucher_creation_failed_description))),
        },
    });
};
export const createVoucherMixpanelEvent = (isMixpanelInitialized, user, createdVoucher) => {
    var _a, _b;
    if (isMixpanelInitialized && user && (createdVoucher === null || createdVoucher === void 0 ? void 0 : createdVoucher.length)) {
        const mixpanelProperties = getMixpanelProperties(user);
        const additionalProperties = {
            module: 'Vouchers',
            'voucher type': createdVoucher.length > 1
                ? VOUCHER_CREATION_TYPE.BULK_CODES
                : VOUCHER_CREATION_TYPE.STANDALONE_CODE,
            'Voucher quantity': createdVoucher.length ? createdVoucher.length : 0,
            'Redemption limit': (_a = createdVoucher[0]) === null || _a === void 0 ? void 0 : _a.max_count,
        };
        const combinedProperties = Object.assign(Object.assign(Object.assign({}, additionalProperties), mixpanelProperties), (createdVoucher.length === 1 && {
            'Voucher code': (_b = createdVoucher[0]) === null || _b === void 0 ? void 0 : _b.code,
        }));
        proviewConsoleVoucherCreated(combinedProperties);
    }
};
export const createVoucherDownloadedEvent = (isMixpanelInitialized, user, createdVoucher) => {
    var _a;
    if (isMixpanelInitialized && user && (createdVoucher === null || createdVoucher === void 0 ? void 0 : createdVoucher.length)) {
        const mixpanelProperties = getMixpanelProperties(user);
        const additionalProperties = {
            module: 'Vouchers',
            'voucher type': createdVoucher.length > 1
                ? VOUCHER_CREATION_TYPE.BULK_CODES
                : VOUCHER_CREATION_TYPE.STANDALONE_CODE,
            'Voucher quantity': createdVoucher.length,
            'Redemption limit': (_a = createdVoucher[0]) === null || _a === void 0 ? void 0 : _a.max_count,
        };
        const combinedProperties = Object.assign(Object.assign({}, additionalProperties), mixpanelProperties);
        proviewConsoleVoucherDownloaded(combinedProperties);
    }
};
export const voucherDownloadListClickedEvent = (isMixpanelInitialized, user, selectedTab) => {
    if (isMixpanelInitialized && user) {
        const mixpanelProperties = getMixpanelProperties(user);
        const additionalProperties = {
            module: 'Vouchers',
            Tab: selectedTab,
        };
        const combinedProperties = Object.assign(Object.assign({}, additionalProperties), mixpanelProperties);
        proviewConsoleVoucherDownloadListClicked(combinedProperties);
    }
};
