import { defineMessages } from 'react-intl';
export const scope = 'app.components.vouchers.confirm_message';
export default defineMessages({
    voucher_ready: {
        id: `${scope}.voucher_ready`,
        defaultMessage: 'Vouchers Created Successfully!',
    },
    voucher_ready_description: {
        id: `${scope}.voucher_ready_description`,
        defaultMessage: 'Your vouchers are ready to use. Download them now or access them anytime from your voucher dashboard.',
    },
    copy_voucher: {
        id: `${scope}.copy_voucher`,
        defaultMessage: 'Copy Code',
    },
    download_now: {
        id: `${scope}.download_now`,
        defaultMessage: 'Download Now',
    },
    copied: {
        id: `${scope}.copied`,
        defaultMessage: 'Copied',
    },
});
