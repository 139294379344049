import { get } from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import fetchData from '@utils/fetchData';
import postData from '@utils/postData';
import { showCreateVoucherErrorToast } from '.';
import { downloadRedeemedVoucherExcel, downloadVoucherExcel, formatBulkVoucherResponse, getFileName, } from './helpers';
import { GET_SKU_LIST_QUERY, GET_VOUCHER_LIST_QUERY, GET_REDEEMED_VOUCHER_LIST_QUERY, CREATE_VOUCHER_MUTATION, } from './queries';
import { getPayVouchersAction, getPayVouchersErrorAction, getPayVouchersSuccessAction, getSkuAction, getSkuSuccessAction, getSkuErrorAction, getRedeemedVoucherAction, getRedeemedVoucherSuccessAction, getRedeemedVoucherErrorAction, createVoucherAction, createVoucherSuccessAction, createVoucherErrorAction, getPayIssuedVouchersSuccessAction, getFullRedeemedVoucherSuccessAction, getFullRedeemedVoucherErrorAction, getPayIssuedVouchersErrorAction, } from './slice';
export function* getVoucherListSaga(action) {
    const { searchConditions, conditions, limit, offset, fetchCompleteList } = action.payload;
    const queryVariables = Object.assign(Object.assign({}, (limit && {
        limit: limit,
    })), { offset: offset, searchExpression: searchConditions, conditions: Object.assign({}, conditions) });
    try {
        const response = yield call(fetchData, {
            queryString: GET_VOUCHER_LIST_QUERY,
            queryKey: 'pay_coupon',
            queryVariables: queryVariables,
            forceRefresh: true,
        });
        const aggregateResponse = yield call(fetchData, {
            queryString: GET_VOUCHER_LIST_QUERY,
            queryKey: 'pay_coupon_aggregate',
            queryVariables: queryVariables,
            forceRefresh: true,
        });
        const count = get(aggregateResponse, 'aggregate.count', 0);
        if (response) {
            if (fetchCompleteList) {
                yield put(getPayIssuedVouchersSuccessAction({ data: response }));
                const filename = getFileName('coupons');
                downloadVoucherExcel(response, filename);
            }
            else {
                yield put(getPayVouchersSuccessAction({ data: response, count: count }));
            }
        }
    }
    catch (error) {
        if (fetchCompleteList) {
            yield put(getPayIssuedVouchersErrorAction(error));
        }
        else {
            yield put(getPayVouchersErrorAction(error));
        }
    }
}
export function* getSkuListSaga() {
    try {
        const response = yield call(fetchData, {
            queryString: GET_SKU_LIST_QUERY,
            queryKey: 'pay_sku',
            forceRefresh: true,
        });
        if (response) {
            yield put(getSkuSuccessAction(response));
        }
    }
    catch (error) {
        yield put(getSkuErrorAction(error));
    }
}
export function* getRedeemedVoucherListSaga(action) {
    const { searchConditions, conditions, limit, offset, fetchCompleteList } = action.payload;
    const queryVariables = {
        limit: limit,
        offset: offset,
        searchExpression: searchConditions,
        conditions: Object.assign({}, conditions),
    };
    try {
        const response = yield call(fetchData, {
            queryString: GET_REDEEMED_VOUCHER_LIST_QUERY,
            queryKey: 'pay_order',
            queryVariables: queryVariables,
            forceRefresh: true,
        });
        const aggregateResponse = yield call(fetchData, {
            queryString: GET_REDEEMED_VOUCHER_LIST_QUERY,
            queryKey: 'pay_order_aggregate',
            queryVariables: queryVariables,
            forceRefresh: true,
        });
        const count = get(aggregateResponse, 'aggregate.count', 0);
        if (response) {
            if (fetchCompleteList) {
                yield put(getFullRedeemedVoucherSuccessAction({ data: response }));
                const filename = getFileName('redeemed_coupons');
                downloadRedeemedVoucherExcel(response, filename);
            }
            else {
                yield put(getRedeemedVoucherSuccessAction({ data: response, count: count }));
            }
        }
    }
    catch (error) {
        if (fetchCompleteList) {
            yield put(getFullRedeemedVoucherErrorAction(error));
        }
        else {
            yield put(getRedeemedVoucherErrorAction(error));
        }
    }
}
export function* createVouchersSaga({ payload, }) {
    try {
        const responses = yield all(payload.map((voucherPayload) => call(postData, {
            queryString: CREATE_VOUCHER_MUTATION,
            payload: voucherPayload,
            spreadPayload: true,
        })));
        const formatedResponse = formatBulkVoucherResponse(responses);
        if (formatedResponse) {
            yield put(createVoucherSuccessAction(formatedResponse));
        }
    }
    catch (error) {
        showCreateVoucherErrorToast();
        yield put(createVoucherErrorAction(error));
    }
}
export function* redeemedVoucherListSaga() {
    yield takeLatest(getRedeemedVoucherAction.type, getRedeemedVoucherListSaga);
}
export function* voucherListSaga() {
    yield takeLatest(getPayVouchersAction.type, getVoucherListSaga);
}
export function* skuListSaga() {
    yield takeLatest(getSkuAction.type, getSkuListSaga);
}
export function* createVoucherSaga() {
    yield takeLatest(createVoucherAction.type, createVouchersSaga);
}
export function* voucherSaga() {
    yield all([
        voucherListSaga(),
        skuListSaga(),
        redeemedVoucherListSaga(),
        createVoucherSaga(),
    ]);
}
