import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
const Pagination = ({ total, pageSize, currentPage, summary, previousPage, getCanPreviousPage, nextPage, getCanNextPage, }) => {
    const startRow = currentPage * pageSize + 1;
    const endRow = Math.min(startRow + pageSize - 1, total);
    return (_jsxs("div", { className: "flex items-center float-right", "data-testid": "pagination", children: [summary && (_jsxs("p", { className: "pr-2 text-sm font-normal text-gray-600 w-55 h-21", children: [_jsx("span", { children: startRow }), " - ", _jsx("span", { children: endRow }), " of", ' ', _jsx("span", { children: total })] })), _jsxs("div", { children: [_jsxs("button", { "data-testid": "prev-page", type: "button", tabIndex: 0, onClick: () => previousPage(), disabled: !getCanPreviousPage(), className: "items-center px-2 py-2 text-sm font-medium text-gray-500 rounded-md hover:bg-neutral-100", children: [_jsx("span", { className: "sr-only", children: _jsx(FormattedMessage, Object.assign({}, messages.previous)) }), _jsx(ChevronLeftIcon, { className: `h-7 w-13 ${!getCanPreviousPage() ? 'fill-gray-300' : ''}`, "aria-hidden": "true" })] }), _jsxs("button", { "data-testid": "next-page", type: "button", tabIndex: 0, onClick: () => nextPage(), disabled: !getCanNextPage() || total <= 0, className: "items-center px-2 py-2 text-sm font-medium text-gray-500 rounded-md hover:bg-neutral-100", children: [_jsx("span", { className: "sr-only", children: _jsx(FormattedMessage, Object.assign({}, messages.next)) }), _jsx(ChevronRightIcon, { className: `h-7 w-13 ${!getCanNextPage() || total <= 0 ? 'fill-gray-300' : ''}`, "aria-hidden": "true" })] })] })] }));
};
export default Pagination;
