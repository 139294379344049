var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import isObject from 'lodash/isObject';
import some from 'lodash/some';
import * as XLSX from 'xlsx';
import { triggerToast } from '@components/Base/Notification';
import { TOAST_VARIANT } from '@utils/data/enums';
import { ValidVoucher, formatDate } from '@utils/dateFormat';
import { getMixpanelProperties } from '@utils/helpers';
import messages from './messages';
import { SELECTED_TAB } from './types';
export const formatInitialFilters = (searchParams) => {
    return () => {
        const queryParams = {};
        for (const [key, value] of searchParams) {
            try {
                queryParams[key] = JSON.parse(decodeURIComponent(value));
            }
            catch (_a) {
                try {
                    queryParams[key] = decodeURIComponent(value);
                }
                catch (e) {
                    console.error('Failed to decode URI: ', e);
                }
            }
        }
        return {
            filters: queryParams.filters || {},
            search: queryParams.search != null && queryParams.search !== ''
                ? String(queryParams.search).replace(/%/g, '')
                : '',
            tab: queryParams.tab || SELECTED_TAB.ISSUED,
        };
    };
};
export const hasNonEmptyFilters = (filters) => {
    return some(filters, (value) => {
        if (isObject(value)) {
            return some(value, Boolean);
        }
        return Boolean(value);
    });
};
export const getFormattedFilters = (appliedFilters) => {
    const { selectedSku, selectedVouchers, selectedExpiresDateRange, selectedCreatedRange, } = appliedFilters;
    return Object.assign(Object.assign(Object.assign(Object.assign({}, ((selectedSku === null || selectedSku === void 0 ? void 0 : selectedSku.length) && {
        sku_coupons: {
            sku_id: { _in: selectedSku.map(({ value }) => value) },
        },
    })), ((selectedVouchers === null || selectedVouchers === void 0 ? void 0 : selectedVouchers.length) && {
        code: { _in: selectedVouchers.map(({ value }) => value) },
    })), (selectedExpiresDateRange &&
        selectedExpiresDateRange.startDate &&
        selectedExpiresDateRange.endDate && {
        valid_till: {
            _gte: selectedExpiresDateRange.startDate,
            _lte: selectedExpiresDateRange.endDate,
        },
    })), (selectedCreatedRange &&
        selectedCreatedRange.startDate &&
        selectedCreatedRange.endDate && {
        created_at: {
            _gte: selectedCreatedRange.startDate,
            _lte: selectedCreatedRange.endDate,
        },
    }));
};
export const getFormattedRedeemedFilters = (appliedFilters) => {
    const { selectedVouchers, selectedExpiresDateRange, selectedCreatedRange, selectedAttendee, } = appliedFilters;
    return Object.assign(Object.assign(Object.assign({}, ((((selectedExpiresDateRange === null || selectedExpiresDateRange === void 0 ? void 0 : selectedExpiresDateRange.startDate) &&
        (selectedExpiresDateRange === null || selectedExpiresDateRange === void 0 ? void 0 : selectedExpiresDateRange.endDate)) ||
        (selectedVouchers === null || selectedVouchers === void 0 ? void 0 : selectedVouchers.length)) && {
        order_items: {
            coupon: Object.assign(Object.assign({}, ((selectedVouchers === null || selectedVouchers === void 0 ? void 0 : selectedVouchers.length) && {
                code: { _in: selectedVouchers.map(({ value }) => value) },
            })), ((selectedExpiresDateRange === null || selectedExpiresDateRange === void 0 ? void 0 : selectedExpiresDateRange.startDate) &&
                (selectedExpiresDateRange === null || selectedExpiresDateRange === void 0 ? void 0 : selectedExpiresDateRange.endDate) && {
                valid_till: {
                    _gte: selectedExpiresDateRange.startDate,
                    _lte: selectedExpiresDateRange.endDate,
                },
            })),
        },
    })), ((selectedCreatedRange === null || selectedCreatedRange === void 0 ? void 0 : selectedCreatedRange.startDate) &&
        (selectedCreatedRange === null || selectedCreatedRange === void 0 ? void 0 : selectedCreatedRange.endDate) && {
        created_at: {
            _gte: selectedCreatedRange.startDate,
            _lte: selectedCreatedRange.endDate,
        },
    })), (selectedAttendee &&
        selectedAttendee.length && {
        ordered_by: { _in: selectedAttendee.map(({ value }) => value) },
    }));
};
export const getFormattedSearchConditions = (searchTerm, selectedTab) => {
    if (selectedTab === SELECTED_TAB.ISSUED) {
        return [
            { code: { _ilike: `%${searchTerm}%` } },
            { description: { _ilike: `%${searchTerm}%` } },
        ];
    }
    else {
        return [
            {
                order_items: {
                    coupon: {
                        code: {
                            _ilike: `%${searchTerm}%`,
                        },
                    },
                },
            },
            {
                order_items: {
                    coupon: {
                        description: {
                            _ilike: `%${searchTerm}%`,
                        },
                    },
                },
            },
        ];
    }
};
export const getCombinedNames = (skuCoupons) => {
    if (skuCoupons === null || skuCoupons === void 0 ? void 0 : skuCoupons.length) {
        return skuCoupons.map((coupon) => { var _a, _b; return (_b = (_a = coupon === null || coupon === void 0 ? void 0 : coupon.item) === null || _a === void 0 ? void 0 : _a.sku) === null || _b === void 0 ? void 0 : _b.name; }).join(', ');
    }
    else {
        return '-';
    }
};
export const getCreatedByName = (orderItems) => {
    var _a, _b, _c, _d, _e, _f, _g;
    return ((_c = (_b = (_a = orderItems === null || orderItems === void 0 ? void 0 : orderItems[0]) === null || _a === void 0 ? void 0 : _a.coupon) === null || _b === void 0 ? void 0 : _b.created_by_details) === null || _c === void 0 ? void 0 : _c.name)
        ? orderItems[0].coupon.created_by_details.name
        : ((_f = (_e = (_d = orderItems === null || orderItems === void 0 ? void 0 : orderItems[0]) === null || _d === void 0 ? void 0 : _d.coupon) === null || _e === void 0 ? void 0 : _e.created_by_details) === null || _f === void 0 ? void 0 : _f.email)
            ? (_g = orderItems[0].coupon.created_by_details) === null || _g === void 0 ? void 0 : _g.email
            : '';
};
export const getExpiresOn = (orderItems) => {
    var _a, _b;
    return ((_b = (_a = orderItems === null || orderItems === void 0 ? void 0 : orderItems[0]) === null || _a === void 0 ? void 0 : _a.coupon) === null || _b === void 0 ? void 0 : _b.valid_till)
        ? ValidVoucher(new Date(orderItems[0].coupon.valid_till))
        : '-';
};
export const validateNumericInput = (value) => {
    return /^\d*$/.test(value) || value === '';
};
export const formateVoucherPayload = (maxUse, voucherDiscount, voucherDescription, selectedSku, expiryDate) => {
    const voucherData = {
        max_count: maxUse ? parseInt(maxUse) : 1,
        discount_percentage: voucherDiscount ? parseInt(voucherDiscount) : 0,
        description: voucherDescription ? voucherDescription : null,
        sku_id: selectedSku ? [{ sku_id: parseInt(selectedSku.value) }] : null,
    };
    if (expiryDate) {
        voucherData['valid_till'] = expiryDate;
    }
    return voucherData;
};
export const formatProperties = (user, selectedTab, searchParam) => {
    const mixpanelProperties = getMixpanelProperties(user);
    const additionalProperties = {
        module: 'Vouchers',
        tab: selectedTab,
    };
    const combinedProperties = Object.assign(Object.assign(Object.assign({}, additionalProperties), mixpanelProperties), { searchTerm: searchParam });
    return combinedProperties;
};
export const createVoucher = (voucherQuantity, createVoucherPayload) => __awaiter(void 0, void 0, void 0, function* () {
    const quantity = parseInt(voucherQuantity);
    const voucherCreationPayloads = [];
    for (let i = 0; i < quantity; i++) {
        voucherCreationPayloads.push(createVoucherPayload);
    }
    return voucherCreationPayloads;
});
export const formatBulkVoucherResponse = (response) => {
    return response.map((voucher) => {
        return voucher.data.pay_create_coupon;
    });
};
// Download Voucher Excel
export const showToast = (variant, title, summary) => {
    triggerToast({
        variant: variant,
        message: {
            title: title,
            summary: summary,
        },
    });
};
const showDownloadToast = (isSuccess) => {
    if (isSuccess) {
        showToast(TOAST_VARIANT.SUCCESS, messages.voucher_download_success_status_header.defaultMessage, messages.voucher_download_success_status_summary.defaultMessage);
    }
    else {
        showToast(TOAST_VARIANT.DANGER, messages.voucher_download_failed_status_header.defaultMessage, messages.voucher_download_failed_status_summary.defaultMessage);
    }
};
const createWorksheetFromData = (data, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Coupons');
    XLSX.writeFile(workbook, fileName);
};
const formatVoucherData = (voucher) => {
    var _a, _b;
    const formattedVoucher = {
        'Voucher Code': voucher.code,
        Discount: voucher.discount_percentage,
        Description: voucher === null || voucher === void 0 ? void 0 : voucher.description,
        SKU: voucher.sku_coupons.map((sku) => sku.sku.name).join(', '),
        Tags: '---',
        'Max Use': voucher.max_count,
        'Expires On': voucher.valid_till,
        'Created by': ((_a = voucher.created_by_details) === null || _a === void 0 ? void 0 : _a.name) || ((_b = voucher.created_by_details) === null || _b === void 0 ? void 0 : _b.email),
        'Created On': formatDate(new Date(voucher.created_at), 'withTime'),
    };
    return formattedVoucher;
};
const formatCreatedVoucherData = (voucher) => {
    const formattedVoucher = {
        'Voucher Code': voucher.code,
        Discount: voucher.discount_percentage,
        Description: voucher === null || voucher === void 0 ? void 0 : voucher.description,
        sku_id: voucher.sku_coupons.map((sku) => sku.sku_id).join(', '),
        Tags: '---',
        'Max Use': voucher.max_count,
        'Expires On': (voucher === null || voucher === void 0 ? void 0 : voucher.valid_till)
            ? formatDate(new Date(voucher === null || voucher === void 0 ? void 0 : voucher.valid_till), 'withTime')
            : '-',
    };
    return formattedVoucher;
};
export const formatRedeemedVoucherData = (voucher) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    const firstOrderItem = (_b = (_a = voucher.order_items) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : null;
    const coupon = (_c = firstOrderItem === null || firstOrderItem === void 0 ? void 0 : firstOrderItem.coupon) !== null && _c !== void 0 ? _c : null;
    const orderedByDetails = (_d = voucher.ordered_by_details) !== null && _d !== void 0 ? _d : null;
    const transactionHistories = (_g = (_f = (_e = voucher.transactions) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.transaction_status_histories) !== null && _g !== void 0 ? _g : [];
    const formattedVoucher = {
        'Voucher Code': (_h = coupon === null || coupon === void 0 ? void 0 : coupon.code) !== null && _h !== void 0 ? _h : '-',
        Discount: (_j = coupon === null || coupon === void 0 ? void 0 : coupon.discount_percentage) !== null && _j !== void 0 ? _j : '-',
        Description: (_k = coupon === null || coupon === void 0 ? void 0 : coupon.description) !== null && _k !== void 0 ? _k : '-',
        SKU: (_m = (_l = voucher.order_items) === null || _l === void 0 ? void 0 : _l.map((item) => { var _a, _b; return (_b = (_a = item.item) === null || _a === void 0 ? void 0 : _a.sku) === null || _b === void 0 ? void 0 : _b.name; }).join(', ')) !== null && _m !== void 0 ? _m : '-',
        Tags: '---',
        Status: (_o = voucher === null || voucher === void 0 ? void 0 : voucher.status) !== null && _o !== void 0 ? _o : '-',
        'External Id': (_p = voucher === null || voucher === void 0 ? void 0 : voucher.external_id) !== null && _p !== void 0 ? _p : '-',
        'Created At': (_q = formatDate(new Date(voucher.created_at), 'withTime')) !== null && _q !== void 0 ? _q : '-',
        'Max Use': (_r = coupon === null || coupon === void 0 ? void 0 : coupon.max_count) !== null && _r !== void 0 ? _r : '-',
        'Expires On': (coupon === null || coupon === void 0 ? void 0 : coupon.valid_till)
            ? formatDate(new Date(coupon === null || coupon === void 0 ? void 0 : coupon.valid_till), 'withTime')
            : '-',
        'Redeemed By': (_t = (_s = orderedByDetails === null || orderedByDetails === void 0 ? void 0 : orderedByDetails.name) !== null && _s !== void 0 ? _s : orderedByDetails === null || orderedByDetails === void 0 ? void 0 : orderedByDetails.username) !== null && _t !== void 0 ? _t : '-',
        'Redeemed On': transactionHistories.length > 0
            ? (_v = formatDate(new Date((_u = transactionHistories[0]) === null || _u === void 0 ? void 0 : _u.updated_at), 'withTime')) !== null && _v !== void 0 ? _v : '-'
            : '-',
    };
    return formattedVoucher;
};
export const downloadVoucherExcel = (data, fileName) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const formattedData = data.map((coupon) => formatVoucherData(coupon));
        yield createWorksheetFromData(formattedData, fileName);
        showDownloadToast(true);
    }
    catch (error) {
        showDownloadToast(false);
    }
});
export const downloadBulkCreatedVoucher = (data, fileName) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const formattedData = data.map((coupon) => formatCreatedVoucherData(coupon));
        yield createWorksheetFromData(formattedData, fileName);
        showDownloadToast(true);
    }
    catch (error) {
        showDownloadToast(false);
    }
});
export const downloadRedeemedVoucherExcel = (data, fileName) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const formattedData = data.map((coupon) => formatRedeemedVoucherData(coupon));
        yield createWorksheetFromData(formattedData, fileName);
        showDownloadToast(true);
    }
    catch (error) {
        showDownloadToast(false);
    }
});
export const getFileName = (name) => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return `${name}_${formattedDate}.xlsx`;
};
