import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { isEmpty, sortBy } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Button } from '@components/Base/Button';
import EmptyMessagePage from '@components/Base/EmptyMessagePage';
import SearchInput from '@components/Base/Input';
import Pagination from '@components/Base/Pagination';
import Tabs from '@components/Base/Tabs';
import VoucherModal from '@components/CreateVoucher';
import { formatProperties } from '@containers/vouchers/helpers';
import messages from '@containers/vouchers/messages';
import { SELECTED_TAB, } from '@containers/vouchers/types';
import { getMixpanelProperties, getPageCount } from '@utils/helpers';
import { useAppSelector } from '@utils/hooks';
import { proviewConsoleVoucherFilterApplied, proviewConsoleVoucherSearched, } from '@utils/mixpanelActions';
import ClipBoardIcon from 'app/images/clipboard.svg';
import NoResultsFound from 'app/images/svgs/noResultsFound.svg';
import VoucherFilters from './VoucherFilters';
import IssuedVoucherTable from './VoucherTable/IssuedTable';
import { createColumns } from './VoucherTable/IssuedTable/columns';
import RedeemedVoucherTable from './VoucherTable/RedeemedTable';
import { createRedeemColumns } from './VoucherTable/RedeemedTable/columns';
const VoucherList = (props) => {
    const { voucherList, searchTerm, isLoading, setSearchTerm, updateAppliedFilters, vouchers, appliedFilters, selectedTab, handleSelectedTab, skuList, redeemedVouchersList, loadingRedeemedVouchers, handleCreateVoucher, loadingCreatedVoucher, createdVoucher, showVoucherSuccessModal, resetVoucherState, redeemedList, resetFilters, totalCount, pagination, setPagination, redeemedVouchersCount, paginationRedeemed, setPaginationRedeemed, createVoucherDownloaded, voucherDownloadListClicked, downloadCompleteList, loadingIssuedVouchersList, loadingRedeemedVoucherFullList, } = props;
    const { user, isMixpanelInitialized } = useAppSelector((state) => state.app);
    const showEmptyPage = isEmpty(appliedFilters);
    const [openVoucherModal, setOpenVoucherModal] = useState(false);
    const [isDownaloadDisabled, setIsDownloadDisabled] = useState(false);
    const pageCount = getPageCount(totalCount, pagination);
    const redeemedPageCount = getPageCount(redeemedVouchersCount, pagination);
    const downloadVoucher = () => {
        downloadCompleteList();
    };
    const renderVouchersEmptyPage = isEmpty(voucherList) &&
        !isLoading &&
        !searchTerm &&
        showEmptyPage &&
        selectedTab === SELECTED_TAB.ISSUED;
    const renderVouchersNoResultsFoundPage = isEmpty(voucherList) &&
        (searchTerm || !showEmptyPage) &&
        selectedTab === SELECTED_TAB.ISSUED;
    const sortedRedeemedData = sortBy(redeemedVouchersList, (order) => { var _a, _b, _c; return (_c = (_b = (_a = order.order_items) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.coupon) === null || _c === void 0 ? void 0 : _c.code; });
    const renderRedeemedVouchersEmptyPage = sortedRedeemedData.length === 0 &&
        !searchTerm &&
        !loadingRedeemedVouchers &&
        selectedTab === SELECTED_TAB.REDEEMED;
    const renderRedeemedVouchersNoResultsFoundPage = sortedRedeemedData.length === 0 &&
        searchTerm &&
        selectedTab === SELECTED_TAB.REDEEMED;
    const columnsIssued = useMemo(() => {
        return createColumns().filter((column) => column !== null);
    }, [selectedTab]);
    const columnsRedeemed = useMemo(() => {
        return createRedeemColumns().filter((column) => column !== null);
    }, [selectedTab]);
    const handleSearch = (value) => {
        if (value) {
            triggerSearchMPEvent(value);
        }
        setSearchTerm(value.replace(/%/g, ''));
    };
    const triggerFilterMPEvent = (filters) => {
        if (isMixpanelInitialized && user) {
            const mixpanelProperties = getMixpanelProperties(user);
            const additionalProperties = {
                module: 'Vouchers',
                tab: selectedTab,
            };
            const combinedProperties = Object.assign(Object.assign(Object.assign({}, additionalProperties), mixpanelProperties), { filteredBy: Object.assign({}, filters) });
            proviewConsoleVoucherFilterApplied(combinedProperties);
        }
    };
    const triggerSearchMPEvent = (searchParam) => {
        proviewConsoleVoucherSearchedEvent(isMixpanelInitialized, user, selectedTab, searchParam);
    };
    const applyFilters = (filters) => {
        if (!isEmpty(filters)) {
            triggerFilterMPEvent(filters);
        }
        updateAppliedFilters(filters);
    };
    const handleTabSelect = (value) => {
        handleSelectedTab(value);
    };
    const toggleModal = () => {
        setOpenVoucherModal((prev) => !prev);
        resetVoucherState();
    };
    const nextPage = () => {
        setPagination((prev) => (Object.assign(Object.assign({}, prev), { pageIndex: prev.pageIndex + 1 })));
    };
    const prevPage = () => {
        setPagination((prev) => (Object.assign(Object.assign({}, prev), { pageIndex: prev.pageIndex - 1 })));
    };
    const nextRedeemedPage = () => {
        setPaginationRedeemed((prev) => (Object.assign(Object.assign({}, prev), { pageIndex: prev.pageIndex + 1 })));
    };
    const prevRedeemedPage = () => {
        setPaginationRedeemed((prev) => (Object.assign(Object.assign({}, prev), { pageIndex: prev.pageIndex - 1 })));
    };
    useEffect(() => {
        const disabledCondition = renderVouchersNoResultsFoundPage ||
            (selectedTab === SELECTED_TAB.REDEEMED &&
                (renderRedeemedVouchersEmptyPage ||
                    renderRedeemedVouchersNoResultsFoundPage));
        setIsDownloadDisabled(Boolean(disabledCondition));
    }, [
        selectedTab,
        renderRedeemedVouchersEmptyPage,
        renderRedeemedVouchersNoResultsFoundPage,
        renderVouchersNoResultsFoundPage,
        voucherList,
        sortedRedeemedData,
    ]);
    return (_jsxs(_Fragment, { children: [renderVouchersEmptyPage ? (_jsx(EmptyMessagePage, { icon: ClipBoardIcon, titleMessage: messages.empty_voucher, descriptionMessage: messages.empty_voucher_description, descriptionWidth: "w-48" })) : (_jsx("div", { className: "flex w-full px-8 py-5", children: _jsxs("div", { className: "flex flex-col w-full", children: [_jsxs("div", { className: "flex flex-row-reverse justify-between items-center mb-4", children: [_jsxs("div", { className: "flex items-center gap-4", children: [_jsx(SearchInput, { initialValue: searchTerm, handleChange: (value) => handleSearch(value), placeholderMessageId: "app.containers.vouchers.search_vouchers", resetSearch: selectedTab }), _jsx(VoucherFilters, { updateAppliedFilters: applyFilters, vouchers: vouchers, appliedFilters: appliedFilters, skuList: skuList, selectedTab: selectedTab, redeemedList: redeemedList, resetFilters: resetFilters }), _jsxs("div", { className: `inline-flex items-center group cursor-pointer ${isDownaloadDisabled ? 'opacity-50 pointer-events-none' : ''}`, children: [_jsx(ArrowDownTrayIcon, { className: "w-5 h-5 text-blue-700 cursor-pointer mr-2 group-hover:text-blue-700" }), _jsx(Button, { type: "button", variant: "ghost", "data-testid": "download-btn", className: "bg-transparent text-blue-700 font-medium text-sm p-0 m-0 \n      group-hover:bg-transparent hover:bg-transparent", onClick: downloadVoucher, isLoading: selectedTab === SELECTED_TAB.ISSUED
                                                        ? loadingIssuedVouchersList
                                                        : loadingRedeemedVoucherFullList, children: _jsx("span", { className: "text-sm font-medium", children: _jsx(FormattedMessage, Object.assign({}, messages.voucher_download_list)) }) })] }), _jsx("div", { className: "w-0 h-6 border border-gray-300" }), _jsx(Pagination, { total: selectedTab === SELECTED_TAB.ISSUED
                                                ? totalCount
                                                : redeemedVouchersCount, pageSize: selectedTab === SELECTED_TAB.ISSUED
                                                ? pagination.pageSize
                                                : paginationRedeemed.pageSize, currentPage: selectedTab === SELECTED_TAB.ISSUED
                                                ? pagination.pageIndex
                                                : paginationRedeemed.pageIndex, summary: true, previousPage: selectedTab === SELECTED_TAB.ISSUED
                                                ? prevPage
                                                : prevRedeemedPage, nextPage: selectedTab === SELECTED_TAB.ISSUED
                                                ? nextPage
                                                : nextRedeemedPage, getCanPreviousPage: () => {
                                                if (selectedTab === SELECTED_TAB.ISSUED) {
                                                    return pagination.pageIndex > 0;
                                                }
                                                else {
                                                    return paginationRedeemed.pageIndex > 0;
                                                }
                                            }, getCanNextPage: () => {
                                                if (selectedTab === SELECTED_TAB.ISSUED) {
                                                    return pagination.pageIndex < pageCount - 1;
                                                }
                                                else {
                                                    return (paginationRedeemed.pageIndex < redeemedPageCount - 1);
                                                }
                                            } })] }), _jsxs("div", { className: "flex items-center", children: [_jsx(Tabs, { tabData: [
                                                {
                                                    label: _jsx(FormattedMessage, Object.assign({}, messages.issued)),
                                                    value: SELECTED_TAB.ISSUED,
                                                    isShow: true,
                                                },
                                                {
                                                    label: _jsx(FormattedMessage, Object.assign({}, messages.redeemed)),
                                                    value: SELECTED_TAB.REDEEMED,
                                                    isShow: true,
                                                },
                                            ], selectedValue: selectedTab, onSelect: handleTabSelect }), _jsx(Button, { className: "ml-2", variant: "base", "data-testid": "create-voucher-btn", onClick: toggleModal, children: _jsx(FormattedMessage, Object.assign({}, messages.create_voucher)) })] })] }), _jsx(_Fragment, { children: selectedTab === SELECTED_TAB.ISSUED ? (renderVouchersNoResultsFoundPage ? (_jsx(EmptyMessagePage, { icon: NoResultsFound, titleMessage: messages.empty_record_title, descriptionMessage: messages.empty_record_description, descriptionWidth: "w-48" })) : (_jsx(IssuedVoucherTable, { data: voucherList, columns: columnsIssued, isLoading: isLoading, pagination: pagination, pageCount: pageCount, setPagination: setPagination }))) : renderRedeemedVouchersEmptyPage ? (_jsx(EmptyMessagePage, { icon: ClipBoardIcon, titleMessage: messages.empty_voucher, descriptionMessage: messages.empty_redeemed_description, descriptionWidth: "w-48" })) : renderRedeemedVouchersNoResultsFoundPage ? (_jsx(EmptyMessagePage, { icon: NoResultsFound, titleMessage: messages.empty_record_title, descriptionMessage: messages.empty_record_description, descriptionWidth: "w-48" })) : (_jsx(RedeemedVoucherTable, { data: sortedRedeemedData, columns: columnsRedeemed, isLoading: loadingRedeemedVouchers, pagination: paginationRedeemed, pageCount: redeemedPageCount, setPagination: setPaginationRedeemed })) })] }) })), _jsx(VoucherModal, { skuList: skuList, handleCreateVoucher: handleCreateVoucher, openVoucherModal: openVoucherModal, toggleModal: toggleModal, loadingCreatedVoucher: loadingCreatedVoucher, createdVoucher: createdVoucher, showVoucherSuccessModal: showVoucherSuccessModal, createVoucherDownloaded: createVoucherDownloaded, voucherDownloadListClicked: voucherDownloadListClicked })] }));
};
export default VoucherList;
export const proviewConsoleVoucherSearchedEvent = (isMixpanelInitialized, user, selectedTab, searchParam) => {
    if (isMixpanelInitialized && user) {
        const combinedProperties = formatProperties(user, selectedTab, searchParam);
        proviewConsoleVoucherSearched(combinedProperties);
    }
};
